table {
  width: 100%;

}
table td {
  word-wrap: break-word;
  white-space: inherit !important;
}
table th {
  white-space: inherit !important;
}
wt-100 {
  width: 50px !important;
}
