:root {
  --bg-color: #12213b;
}

#resumo td:nth-child(3),
#resumo td:nth-child(4) {
  background-color: var(--bg-color);
}

#resumo thead th:nth-child(3),
#resumo thead th:nth-child(4) {
  background-color: var(--bg-color);
}

#resumo td:nth-child(7),
#resumo td:nth-child(8) {
  background-color: var(--bg-color);
}

#resumo thead th:nth-child(7),
#resumo thead th:nth-child(8) {
  background-color: var(--bg-color);
}
