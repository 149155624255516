body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.img-icon-sm {
  width: 20px !important;
  height: 20px !important;
  margin-right: 12px !important;
}

.text-xsm {
  font-size: 0.765rem !important;
  font-weight: 300;
  line-height: 14px;
}

.img-icon {
  width: 20px !important;
  height: 20px !important;
  margin-top: 8px !important;
}

ul#console {
  list-style-type: none;
  font-family: "Roboto Mono", monospace;
  font-size: 14px;
  line-height: 25px;
  padding-left: 5px;
  text-align: left !important;
  max-height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
}
ul#console li {
  border-bottom: solid 1px #80808038;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.zoom:hover {
  cursor: zoom-in;
}

.progress-bar {
  width: calc(100% - 6px);
  height: 5px;
  background: #e0e0e0;
  padding: 3px;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2);
}

.progress-bar-fill {
  display: block;
  height: 5px;
  background: #659cef;
  border-radius: 3px;
  /*transition: width 250ms ease-in-out;*/
  transition: width 5s ease-in-out;
}